import React, { useState } from 'react'

const UpArrow = () => {

    const [show, setShow] = useState("");


    const changeNavBg = () => {
        window.scrollY >= 300 ? setShow("block") : setShow("none");
    }

    window.addEventListener('scroll', changeNavBg);

    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <div className='uparrow' onClick={up} style={{ display: show }}>
            <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2_00000147185944016977918850000009312815276845071522_"><g id="chevron_up"><path d="m1 373.8c3 13.8 11.2 23.1 25.2 27 15.3 4.3 28-.4 38.8-11.3 41.9-42.1 83.7-84.1 125.6-126.2 21-21 42.2-41.9 65.4-65l191.4 191.4c16 16 38.8 16.9 53.6 2.9 14.8-14.1 15-38-.6-53.7-72.2-72.6-144.7-145.1-217.3-217.3-15.8-15.7-37.9-16.1-53.6-.6-73 72.5-145.7 145.2-218.2 218.1-9.5 9.5-13.3 21.4-10.3 34.7z" /></g></g></svg>

        </div>
    )
}

export default UpArrow
