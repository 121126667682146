import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const HeaderInner = () => {

    const [menuVisible, setMenuVisible] = useState(false);

    const showProduct = () => {
        setMenuVisible(current => !current);
    }

    const down = () => {
        window.scrollTo({ top: 6000, left: 0, behavior: 'smooth' });
    }

    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    const [mobileNav, setMobileNav] = useState({
        display: "none"
    });

    const showMenu = () => {
        setMobileNav({
            display: "flex"
        })
    }

    const hideMenu = () => {
        setMobileNav({
            display: "none"
        })
    }

    return (
        <div>
            <header style={{ top: "0", transition: "none" }}>
                <Link to={"/"} className="header-logo">
                    <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1703923010/Murine/logo_vfgcul.png" alt="logo" />
                </Link>

                <div className="header-links">
                    <Link className='h-l' to={"/"}>Home</Link>
                    <div className='prod'>
                        <p onClick={showProduct}>Products &emsp;
                            <svg enable-background="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Arrow-13"><path d="m54.9210777 20.296875c-.15625-.3701172-.5185547-.6108398-.9208984-.6108398l-44 .0004883c-.4018555 0-.7646484.2407227-.9213867.6108398-.15625.3701172-.0756836.7983398.2045898 1.0864258l22 22.6274414c.1879883.1933594.4467773.3027344.7167969.3027344s.5288086-.109375.7167969-.3027344l22-22.6279297c.2802734-.2885742.3603515-.7163086.2041015-1.0864258z" /></g></svg>
                        </p>
                        {menuVisible && (
                            <div className="prod-dropdown">
                                <Link className='prod-link' to={"/products/murine-300"}>Murine 300</Link>
                                <Link className='prod-link' to={"/products/murine-xt"}>Murine XT</Link>
                            </div>
                        )}
                    </div>
                    <Link className='h-l' onClick={down} to={"#contact"}>Contact Us</Link>
                    <Link className='h-l' to={"/#buy"}>Buy Now</Link>
                </div>

                <svg onClick={showMenu} className='menu' id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2_00000085955729786359348650000010221403479196202385_"><g id="menu"><path d="m60.7 0h117.6c33.5 0 60.7 27.2 60.7 60.7v117.9c0 33.5-27.2 60.7-60.7 60.7h-117.6c-33.5 0-60.7-27.2-60.7-60.7v-117.9c0-33.5 27.2-60.7 60.7-60.7z" /><path d="m333.7 0h117.6c33.5 0 60.7 27.2 60.7 60.7v117.9c0 33.5-27.2 60.7-60.7 60.7h-117.6c-33.5 0-60.7-27.2-60.7-60.7v-117.9c0-33.5 27.2-60.7 60.7-60.7z" /><path d="m60.7 272.7h117.6c33.5 0 60.7 27.2 60.7 60.7v117.9c0 33.5-27.2 60.7-60.7 60.7h-117.6c-33.5 0-60.7-27.2-60.7-60.7v-117.9c0-33.5 27.2-60.7 60.7-60.7z" /><path d="m333.7 272.7h117.6c33.5 0 60.7 27.2 60.7 60.7v117.9c0 33.5-27.2 60.7-60.7 60.7h-117.6c-33.5 0-60.7-27.2-60.7-60.7v-117.9c0-33.5 27.2-60.7 60.7-60.7z" /></g></g></svg>

                <div className="header-links-mobile" style={{ display: mobileNav.display }}>
                    <div className="svg" onClick={hideMenu}>
                        <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2_00000019666308414107203810000016359489542308255153_"><g id="croos"><path d="m325.6 256 172-172c19.3-19.2 19.3-50.3.2-69.6-19.2-19.3-50.3-19.3-69.6-.2 0 0-.1.1-.1.1l-172 172-172.1-171.9c-19.2-19.2-50.4-19.2-69.6 0s-19.2 50.4 0 69.6l172 172-172 172c-19.2 19.2-19.2 50.4 0 69.6s50.4 19.2 69.6 0l172-172 172 172c19.2 19.2 50.4 19.2 69.6 0s19.2-50.4 0-69.6z" /></g></g></svg>
                    </div>
                    <Link className='h-l' to={"/"}>Home</Link>
                    <div className='prod'>
                        <p onClick={showProduct}>Products &emsp;
                            <svg enable-background="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Arrow-13"><path d="m54.9210777 20.296875c-.15625-.3701172-.5185547-.6108398-.9208984-.6108398l-44 .0004883c-.4018555 0-.7646484.2407227-.9213867.6108398-.15625.3701172-.0756836.7983398.2045898 1.0864258l22 22.6274414c.1879883.1933594.4467773.3027344.7167969.3027344s.5288086-.109375.7167969-.3027344l22-22.6279297c.2802734-.2885742.3603515-.7163086.2041015-1.0864258z" /></g></svg>
                        </p>
                        {menuVisible && (
                            <div className="prod-dropdown">
                                <Link className='prod-link' to={"/products/murine-300"}>Murine 300</Link>
                                <Link className='prod-link' to={"/products/murine-xt"}>Murine XT</Link>
                            </div>
                        )}
                    </div>
                    <Link className='h-l' onClick={down} to={"#contact"}>Contact Us</Link>
                    <Link className='h-l' to={"/#buy"}>Buy Now</Link>
                </div>


            </header>



        </div>
    )
}

export default HeaderInner
