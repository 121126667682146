import React, { useEffect } from 'react'
import Footer from '../Utilities/Footer'
import HeaderInner from '../Utilities/HeaderInner';
import "../Sass/PrivacyPolicy.scss"

const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <HeaderInner />
            <div className="privacy-policy">

                <div className="section-title">
                    <h2>Privacy Policy</h2>
                </div>

                <p><b>Last Updated: 5 DEC 2023</b></p>
                <p>Welcome to Akcent Healthcare Pvt Ltd ("us", "we", or "our"). Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website.</p>

                <ol>
                    <li>Information We Collect:</li>

                    <ul>
                        <li>Personal Information: <br />
                            <p>We may collect personally identifiable information, such as your name, contact details, email address, quantity, etc when you voluntarily submit it through our contact forms or other interactions.</p>
                        </li>

                        <li>Usage Data:<br />
                            <p>We may collect information on how the website is accessed and used ("Usage Data"). This data may include your computer's Internet Protocol address, browser type, browser version, the pages you visit, the time and date of your visit, and other diagnostic data.</p>
                        </li>
                    </ul>

                    <li>How We Use Your Information</li>

                    <ul>
                        <li>Personal Information: <br />
                            <p>We may use your personal information to contact you with newsletters, marketing, or promotional materials, and other information that may be of interest to you.</p>
                        </li>

                        <li>Usage Data:<br />
                            <p>We use Usage Data to analyze and improve the functionality and content of our website, to understand user preferences, and to enhance user experience.</p>
                        </li>
                    </ul>

                    <li>Cookies and Tracking Technologies</li>

                    <ul>
                        <li>Cookies: <br />
                            <p>We use cookies to track the activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
                        </li>
                    </ul>

                    <li>Third-Party Links</li>

                    <ul>
                        <li>
                            External Websites:<br />
                            <p>Our website may contain links to external sites that are not operated by us. We have no control over and assume no responsibility for, the content, privacy policies, or practices of any third-party sites.</p>
                        </li>

                    </ul>

                    <li>Data Security</li>

                    <ul>
                        <li>
                            Security Measures:<br />
                            <p>We employ industry-standard security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>
                        </li>

                    </ul>

                    <li>Changes to This Privacy Policy</li>

                    <ul>
                        <li>
                            Updates:<br />
                            <p>We reserve the right to update our Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                        </li>

                    </ul>

                    <li>Contact Us</li>

                    <ul>
                        <li>
                            Questions:<br />
                            <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:akcenthealthcare@gmail.com">akcenthealthcare@gmail.com</a> </p>
                        </li>

                    </ul>

                </ol>
                <p>By using our website, you agree to the collection and use of information by following this Privacy Policy. </p>
                <p>Please read this Privacy Policy carefully and ensure that you understand it. Your continued use of our website constitutes your acceptance of this Privacy Policy and any updates.</p>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
