import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const FooterInner = () => {

    const [animation, setAnimation] = useState("zoom-in-zoom-out 2s ease-out 1");

    const down = () => {
        window.scrollTo({ top: 10000, left: 0 });


        if (animation === "zoom-in-zoom-out 2s ease-out 1") {
            setAnimation("none");
        }
        else {
            setAnimation("zoom-in-zoom-out 2s ease-out 1");
        }
    }



    return (
        <div>
            <footer>
                <div className="footer-body">
                    <div className="footer-about">
                        <h2>murine - <span>a range from Akcent Healthcare India Pvt Ltd</span></h2>
                        <p>Murine is a brand which is a composition of multivitamin multi mineral and vitamins  such as Co enzyme q10, omega 3 fatty acid  lycopene etc</p>
                    </div>
                    <div className="footer-links">
                        <h4>useful Links</h4>
                        <div className="footer-links-content">
                            <a href="/"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Home</a>

                            <Link to={"/#buy"}><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Buy</Link>

                            <a onClick={down} href="#contact"><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Contact</a>

                            <Link to={"/privacy-policy"}><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Privacy Policy</Link>

                        </div>

                    </div>

                    <div className="footer-links">
                        <h4>Products</h4>
                        <div className="footer-links-content">
                            <Link to={"/products/murine-300"}><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Murine 300</Link>

                                                    <Link to={"/products/murine-xt"}><svg id="Layer_1" enable-background="new 0 0 100 100" height="512" viewBox="0 0 100 100" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16.1 45c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m26.1 20c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.2-5 5-5z" /><path d="m21.1 75c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z" /><path d="m43 55.6-6.6 26.6c-.7 2.7 2.4 4.8 4.6 3.2l46.6-32.9c1.7-1.2 1.7-3.7 0-4.9l-46.6-32.9c-2.3-1.6-5.3.5-4.6 3.2l6.6 26.5c.3 1.3 1.5 2.3 2.9 2.3h7.2c1.1 0 2.1-.5 2.6-1.5 1.7-3.2 5.1-5.3 9-5.2 5.2.1 9.5 4.5 9.7 9.7.2 5.7-4.4 10.3-10 10.3-3.8 0-7-2.1-8.7-5.2-.5-.9-1.5-1.5-2.6-1.5-2 0-5.1 0-7.2 0-1.3 0-2.5 1-2.9 2.3z" /></svg>&emsp;Murine XT</Link>

                        </div>
                    </div>

                    <div className="footer-contact" style={{ animation: animation }}>
                        <h4>Contact info</h4>

                        <div className="footer-contact-body">
                            <div className="footer-contact-content">
                                <div className="svg">
                                    <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Call"><path d="m52.5 42.85-2.83-2.82a5.9873 5.9873 0 0 0 -8.48 0l-1.27 1.26a1.9573 1.9573 0 0 1 -1.83.54c-2.1-.47-5.49-1.92-9.74-6.17-4.26-4.26-5.71-7.65-6.18-9.75a1.9573 1.9573 0 0 1 .54-1.83l1.27-1.27a6.01768 6.01768 0 0 0 0-8.48l-2.83-2.83a6.01143 6.01143 0 0 0 -8.48 0l-.87.87a10.64659 10.64659 0 0 0 -2.89 9.89c1.49 6.71 6.41 15.28 11.98 20.85 5.57 5.56 14.14 10.49 20.84 11.98a10.96437 10.96437 0 0 0 2.34.26 10.68538 10.68538 0 0 0 7.56-3.15l.87-.87a6.01143 6.01143 0 0 0 0-8.48z" /></g></svg>
                                </div>

                                <a href="tel:+91-1145009554">+91-1145009554</a>
                            </div>
                            <div className="footer-contact-content">
                                <div className="svg">
                                    <svg viewBox="0 -77 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m490.753906 0h-469.507812l234.753906 234.453125zm0 0" /><path d="m0 21.179688v316.445312l158.425781-158.222656zm0 0" /><path d="m256 276.851562-76.347656-76.25-158.40625 158.203126h469.507812l-158.40625-158.203126zm0 0" /><path d="m353.574219 179.402344 158.425781 158.222656v-316.445312zm0 0" /></svg>
                                </div>

                                <a href="mailto:akcenthealthcare@gmail.com" style={{ textTransform: 'lowercase' }}>akcenthealthcare@gmail.com</a>
                            </div>
                            <div className="footer-contact-content">
                                <div className="svg">
                                    <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-105.6 0-191.5 85.9-191.5 191.5 0 34.4 16.2 77.8 49.4 132.7 27.6 45.6 61.8 90.9 91.9 130.8 13.9 18.4 27 35.8 37.1 50.2 3 4.3 7.9 6.8 13.1 6.8s10.1-2.5 13.1-6.8c10.1-14.4 23.2-31.8 37.1-50.2 30.1-39.9 64.2-85.2 91.9-130.8 33.2-54.9 49.4-98.3 49.4-132.7 0-105.6-85.9-191.5-191.5-191.5zm0 280c-48.8 0-88.5-39.7-88.5-88.5s39.7-88.5 88.5-88.5 88.5 39.7 88.5 88.5-39.7 88.5-88.5 88.5z" /></svg>
                                </div>

                                <a target='_blank' rel='noreferrer' href="https://www.google.com/maps?ll=28.649983,77.145366&z=12&t=m&hl=en&gl=IN&mapclient=embed&cid=6992852429798267520">7/27 Kriti Nagar Industrial Area New Delhi-110015</a>
                            </div>
                        </div>

                        <div className="footer-follow">

                            <div className="footer-follow-top">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/akcenthealthcaresales/">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                                </a>

                                <a target="_blank" rel="noreferrer" href="https://twitter.com/AkcentHealthca1">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                </a>

                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/akcent-healthcare-india-pvt-ltd/about/">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg></a>
                            </div>
                        </div>

                    </div>
                    <div className="footer-map">
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28010.600781844332!2d77.145366!3d28.649983000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d033a653f51a9%3A0x610b9a3c34da5a80!2sAkcent%20Healthcare%20(INDIA)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1700049996399!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

                <div className="footer-copyright">
                    <p>© Copyright {new Date().getFullYear()}. All Rights Reserved by <a target="_blank" rel="noreferrer" href="https://www.akcenthealthcare.com">Akcent Healthcare Pvt. Ltd.</a></p>
                </div>
            </footer >
        </div>
    )
}

export default FooterInner
